




import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import MasterEducationModule from '@/store/modules/master/education'
import SystemModule from '@/store/modules/system'
import NotifyMixin from '@/mixins/NotifyMixin'
import MasterExercisesModule from '@/store/modules/master/exercises'
import AuthModule from '@/store/modules/auth'

@Component
export default class Master extends Mixins(NotifyMixin) {
  private isReady = false

  private get masterGroups() {
    return MasterEducationModule.masterGroups
  }

  private get hasSocials() {
    return AuthModule.self?.hasSocials
  }

  private async created () {
    this.$bus.$on('resetMonthExerciseFilter', this.resetMonthExerciseFilter)
    SystemModule.setInterface('master')

    // Нужно знать заранее в каких мастер-группах находится ученик.
    // Сразу же выставляется активный и используется как значение в селектах мастер-групп и на дашборде мастера
    // не нужно запрашивать мастер-группы, если у пользователя не привязан вк, чтобы он мог переходить в техподдержку
    if (!this.masterGroups.length && this.hasSocials) {
      MasterEducationModule.fetchMasterGroups()
        .catch((error: any) => {
          if (error.response && error.response.status === 425) {
            this.$router.replace({
              name: 'profile',
            })
          }
          this.notifyError(error)
        })
        .finally(() => {
          this.isReady = true
        })
    } else {
      this.isReady = true
    }
  }

  private destroyed() {
    this.$bus.$off('resetMonthExerciseFilter', this.resetMonthExerciseFilter as any)
  }

  private resetMonthExerciseFilter() {
    MasterExercisesModule.resetExerciseContainersFilterMonth()
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Мастер',
    }
  }
}
